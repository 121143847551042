<template>
  <div
    class="d-flex my-16"
    :class="{
      'flex-column': windowWidth <= 600,
      'justify-center': windowWidth > 600,
    }"
  >
    <v-btn
      @click="votingCards(false)"
      class="ml-5 default-btn mx-8"
      :class="{ active: !showVotingCards }"
      outlined
      text
      >Nowe Głosowanie</v-btn
    >
    <v-btn
      @click="votingCards(true)"
      class="ml-5 default-btn mx-8"
      :class="{ active: showVotingCards, 'mt-5': windowWidth <= 600 }"
      outlined
      text
      >W trakcie głosowania</v-btn
    >
  </div>
</template>
<script>
  export default {
    data() {
      return {
        windowWidth: window.innerWidth,
        showVotingCards: true,
      };
    },

    methods: {
      votingCards(value) {
        this.showVotingCards = value;
        this.$emit("showVoting", value);
      },

      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },

    mounted() {
      window.addEventListener("resize", this.handleResize);
    },

    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
</script>
<style lang="scss" scoped>
  .active {
    background: #6200ee;
    color: white !important;
    border: transparent !important;
  }

  .default-btn {
    height: 56px !important;
  }
</style>
