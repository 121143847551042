<template>
  <div class="main d-flex justify-center my-16">
    <div class="flex-column">
      <h1 class="page-name text-center text-uppercase">{{ name }}</h1>
      <div class="page-subtitle text-center text-uppercase">{{ description }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: "",
      },

      description: {
        type: String,
        default: "",
      },
    },

    data() {
      return {};
    },

    watch: {},

    computed: {},

    methods: {},

    mounted() {},
  };
</script>

<style type="text/css">
  .page-subtitle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(0, 0, 0, 0.87);
  }
</style>
