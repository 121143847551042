<template>
  <div
    class="my-10"
    :class="{
      'd-flex align-center': actionType !== 2 && windowWidth > 400,
      'd-flex flex-column': windowWidth <= 400,
    }"
  >
    <v-text-field
      v-if="actionType === 0"
      class="my-5 w400"
      v-model="actionAddress"
      label="Adres portfela"
      hide-details="auto"
      outlined
      :error="actionAddress ? !isValidEthWallet(actionAddress) : false"
    />

    <v-combobox
      v-if="actionType === 1"
      class="my-5 w400"
      v-model="actionAddress"
      :items="participantsAdresses"
      placeholder="Adres portfela"
      hide-details="auto"
      clearable
      outlined
    />

    <div v-if="actionType === 2" class="my-3">
      <div class="d-flex justify-end">
        <v-btn
          @click="createProposal"
          :disabled="!isSumCorrect"
          class="create-btn"
          outlined
          text
          >{{ getButtonText }}</v-btn
        >
      </div>

      <v-card
        class="mt-3"
        v-for="(percent, index) in participantsPercents"
        :key="index"
        elevation="2"
      >
        <v-card-text>
          <div class="d-flex align-center">
            <Avatar :name="participantsAdresses[index].toString()" :size="40" />
            <div class="ml-5">
              {{ $shortAddress(participantsAdresses[index], windowWidth) }}
            </div>

            <v-text-field
              class="w100 ml-auto"
              dense
              hide-details
              type="number"
              v-model="participantsPercents[index]"
              outlined
              suffix="%"
              :error="!isSumCorrect"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>

    <v-text-field
      v-if="actionType === 3"
      class="my-5 w400"
      v-model="amount"
      label="Kwota wypłaty (ETH)"
      placeholder="Wypłata w ETH"
      hide-details="auto"
      outlined
      :error="!isValidEth(amount)"
    />

    <v-btn
      v-if="actionType !== 2"
      :disabled="!isValidEth(amount) || !isValidEthWallet(actionAddress)"
      @click="createProposal"
      class="create-btn"
      :class="{ 'ml-5': windowWidth > 400 }"
      outlined
      text
      >{{ getButtonText }}</v-btn
    >
  </div>
</template>

<script>
  import BigNumber from "bignumber.js";
  import WAValidator from "wallet-address-validator";
  import Avatar from "../components/avatar";

  export default {
    props: {
      actionType: {
        type: Number,
        default: 0,
      },
    },

    components: { Avatar },

    data() {
      return {
        windowWidth: window.innerWidth,
        actionAddress: "",
        amount: 0.00001,
        participantsAdresses: [],
        participantsPercents: [],
      };
    },

    watch: {
      amount(value) {
        this.amount = value.replace(/,/g, ".");
      },
    },

    computed: {
      web3() {
        return this.$store.state.web3;
      },

      proprosals() {
        return this.$store.state.web3.proprosals;
      },

      getButtonText() {
        const { actionType } = this;

        switch (actionType) {
          case 0:
            return "Dodaj portfel";
          case 1:
            return "Usuń portfel";
          default:
            return "Dodaj głosowanie";
        }
      },

      isSumCorrect() {
        const { participantsPercents } = this;
        if (participantsPercents.length == 0) return true;
        const count = participantsPercents.reduce((a, b) => {
          return parseInt(a) + parseInt(b);
        });

        const info = {
          text: "Suma musi wynosić 100%",
          type: "error",
        };

        if (count > 100) {
          this.$emit("info", info);
          return false;
        }

        if (count < 100) {
          return false;
        }

        return true;
      },
    },

    methods: {
      isValidEthWallet(address) {
        return WAValidator.validate(address, "ETH");
      },

      isValidEth(eth) {
        const ethString = eth.toString();
        const match = ethString.match(
          /(?!^0*$)(?!^0*\.0*$)^\d{1,10}(\.\d{1,50})?$/gm
        );

        return match ? true : false;
      },

      toWei(eth) {
        return new BigNumber(eth).multipliedBy(10 ** 18);
      },

      handleResize() {
        this.windowWidth = window.innerWidth;
      },

      async createProposal() {
        try {
          const result = await this.$web3Call(
            "beforeCreateProposal",
            this.actionType,
            this.actionAddress,
            this.participantsPercents.map(parseFloat),
            this.participantsAdresses,
            this.web3.account
          );

          if (result[0]) {
            this.info = { text: result[1], type: "error" };
            this.$emit("info", this.info);
            return;
          }

          const receipt = await this.$web3Send(
            "createProposal",
            {},
            this.actionType,
            this.actionAddress,
            this.participantsPercents.map(parseFloat),
            this.participantsAdresses,
            this.toWei(this.amount).toString()
          );

          this.info = {
            text: "Głosowanie stworzone",
            type: "success",
          };

          this.$emit("info", this.info);
        } catch (error) {
          console.error(error);
          this.info = { text: "Error: something went wrong", type: "error" };
          this.$emit("info", this.info);
        }
      },

      async getParticipants() {
        const info = await this.$web3Call("percentagePayouts");
        this.participantsAdresses = info.participantsAdresses;
        this.participantsPercents = info.percents.map(parseFloat);
      },
    },

    mounted() {
      window.addEventListener("resize", this.handleResize);
      const { actionType } = this;

      if (actionType === 2 || actionType === 3) {
        this.actionAddress = "0x0000000000000000000000000000000000000000";
      }

      if (actionType === 1 || actionType === 2) {
        this.getParticipants();
      }
    },

    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
</script>

<style lang="scss" scoped>
  .w100 {
    max-width: 100px;
  }

  .w400 {
    max-width: 400px;
  }

  .create-btn {
    height: 56px !important;
    background: rgba(212, 175, 55, 0.87);
    color: white !important;
    border: transparent;
  }

  .theme--light.v-btn.v-btn--disabled {
    color: white !important;
  }
</style>
