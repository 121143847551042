<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!mobile"
        class="purple-button mx-5"
        v-bind="attrs"
        v-on="on"
        text
        small
        >Szczegóły Głosowania</v-btn
      >
      <v-btn v-else class="purple-button" v-bind="attrs" v-on="on" text small
        ><v-icon>mdi-dots-horizontal</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="justify-space-between">
        GŁOSY
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="addresses.length">
        <div
          class="d-flex align-center mb-2"
          v-for="(a, i) in addresses"
          :key="i"
        >
          <v-chip dark small class="mr-3" :class="voteObject(votes[i]).style">
            {{ voteObject(votes[i]).text }}
          </v-chip>

          {{ $shortAddress(a, windowWidth) }}
        </div>
      </v-card-text>
      <v-card-text v-else> 
        <div class="text-center py-10">Nie oddano głosu</div></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        default: 0,
      },

      mobile: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        dialog: false,
        addresses: [],
        votes: [],
        windowWidth: window.innerWidth,
      };
    },

    watch: {
      async dialog(show) {
        if (show) {
          const result = await this.$web3Call("statusOfProposal", this.index);

          this.addresses = result[0];
          this.votes = result[1].map(parseFloat);
        }
      },
    },

    computed: {
      web3() {
        return this.$store.state.web3;
      },
    },

    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },

      voteObject(vote) {
        switch (vote) {
          case 0:
            return { text: "Nie", style: "red" };
          case 1:
            return { text: "Tak", style: "green" };
          case 2:
            return { text: "Zrezygnował", style: "orange" };
          default:
            return {};
        }
      },
    },

    mounted() {
      window.addEventListener("resize", this.handleResize);
    },

    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
</script>
<style lang="scss" scoped>
  .purple-button {
    background: rgba(98, 0, 238, 0.08);
    color: #6200ee !important;
    border-color: #6200ee !important;
  }
</style>
